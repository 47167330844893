import React from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// Markdown
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

// MUI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'

// Node Components
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
const handleDragStart = e => e.preventDefault()

// Local Components
import Layout from '../components/Layout'
import { useStyles } from '../styles/global'

const Index = ({ data }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { allMarkdownRemark } = data
  const { frontmatter } = allMarkdownRemark?.edges[0].node

  return (
    <div>
      <Layout>
        <Grid container justify="center">
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            mt={['1rem', '2rem']}
            mb={['1rem', '2rem']}
            p={['.5rem', '1rem', '2rem']}
            bgcolor={theme.palette.opaque.default}
          >
            <Grid item xs={12} sm={8}>
              <AliceCarousel
                animationDuration={1000}
                animationType="fadeout"
                autoPlay
                autoPlayInterval={3300}
                autoPlayStrategy="none"
                disableButtonsControls
                disableDotsControls
                infinite
                keyboardNavigation
                mouseTracking
                items={frontmatter?.images?.map(
                  ({ image }, index) => (
                    <GatsbyImage
                      key={index}
                      image={getImage(image)}
                      alt=""
                      loading="eager"
                      onDragStart={handleDragStart}
                      objectFit="cover"
                    />
                  ),
                )}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item xs={12} md={10}>
            <Box
              display="flex"
              flexDirection="column"
              p={['1rem']}
              mb={['1rem']}
            >
              <Typography
                variant="h2"
                className={classes.pacifico}
              >
                {frontmatter.slogan}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item xs={12} md={10}>
            <Box
              display="flex"
              flexDirection="column"
              p={['1rem']}
            >
              <Typography
                variant="h4"
                className={classes.lightCentered}
              >
                {frontmatter.pitch}
              </Typography>
            </Box>
          </Grid>
          <Grid container justify="space-evenly">
            <Grid item xs={12} md={10}>
              <Box
                mt={['1rem']}
                mb={['rem']}
                display="flex"
                justifyContent="space-evenly"
              >
                <Box
                  pb={['.5rem']}
                  borderBottom={`.3rem solid ${theme.palette.primary.main}`}
                  width="fit-content"
                >
                  <Typography
                    className={classes.pacifico}
                    variant="h4"
                  >
                    {frontmatter.contactLabel}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box
                display="flex"
                flexDirection="column"
                p={['1rem']}
              >
                <Typography
                  variant="h5"
                  className={classes.regCentered}
                >
                  {frontmatter.contactText}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={10}>
              <Box
                mt={['1rem']}
                mb={['1rem']}
                display="flex"
                justifyContent="space-around"
              >
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/contact')}
                >
                  contact
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "index" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            contactLabel
            contactText
            slogan
            pitch
            mission
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    aspectRatio: 1.77
                    formats: [AUTO, WEBP, AVIF]
                    quality: 80
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Index
